import React, { useRef, useEffect } from "react"
import introVideo from "../assets/video/intro.mp4"
import { navigate } from "gatsby"

export default function Qr({ children, classNames }) {
  const videoRef = useRef(null)

  useEffect(() => {
    const video = videoRef.current

    const handleUserInteraction = () => {
      if (video && video.paused) {
        video.play()
      }
      document.removeEventListener("touchstart", handleUserInteraction)
    }

    document.addEventListener("touchstart", handleUserInteraction)

    return () => {
      document.removeEventListener("touchstart", handleUserInteraction)
    }
  }, [])

  const handleVideoEnded = () => {
    navigate("/home")
  }

  return (
    <div
      style={{ backgroundColor: "#3A3938" }}
      className="h-screen w-screen overflow-hidden flex items-center justify-center"
    >
      <video
        ref={videoRef}
        src={introVideo}
        autoPlay
        onEnded={handleVideoEnded}
        className="w-full h-full object-cover"
        muted
        playsInline
      />
    </div>
  )
}
